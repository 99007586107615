import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'
import { mediaTabs } from '../templates/MediaPage.context'
import { Strapi_Enum_Mediapost_Type } from '../_generated/codegen/graphqlTypes'

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface TabLinkProps {
  active?: 'true'
}

const TabLink = styled(Link)<TabLinkProps>`
  width: 100vw;
  max-width: 160px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ${({ active }) => {
    if (active === 'true') {
      return css`
        background-color: ${({ theme }) => theme.colors.secondary};
      `
    } else {
      return css`
        background-color: ${({ theme }) => theme.colors.primary};
      `
    }
  }}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 22px;
    line-height: 26px;
    max-width: 270px;
    padding: 30px 12px;
  }
`

interface MediaTabsProps {
  type?: Strapi_Enum_Mediapost_Type
}

const MediaTabs: React.FC<MediaTabsProps> = ({ type }) => (
  <List>
    {mediaTabs.map((item, index) => (
      <li key={index}>
        <TabLink active={type === item.type ? 'true' : undefined} to={item.url}>
          {item.label}
        </TabLink>
      </li>
    ))}
  </List>
)

export default MediaTabs
