import uniq from 'lodash/uniq'

const generatePaginationArray = (
  numPages: number,
  currentPage: number,
  neighbours: number
) => {
  const left = Array.from({ length: neighbours })
    .map((_, i) => currentPage - 1 - i)
    .filter((x) => x >= 0)

  const right = Array.from({ length: neighbours })
    .map((_, i) => currentPage + 1 + i)
    .filter((x) => x < numPages)

  return uniq([0, ...left, currentPage, ...right, numPages - 1]).sort(
    (a, b) => a - b
  )
}

export default generatePaginationArray
