import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import generatePaginationArray from '../utils/generatePaginationArray'

const Container = styled.nav`
  display: inline-flex;
  gap: 7px;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0;
`

const ArrowLink = styled(Link)`
  display: inline-flex;
`

const DigitLink = styled(Link)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
  text-decoration: none;
  padding: 3px 6px;
`

const Current = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  border-radius: 10rem;
  padding: 7px 4px;
  width: 29px;
`

interface PaginationProps {
  numPages: number
  currentPage: number
  pathBuilder: (i: number) => string
  className?: string
}

const Pagination: React.FC<PaginationProps> = ({
  numPages,
  currentPage,
  pathBuilder,
  className,
}) => {
  const paginationArray = useMemo(
    () => generatePaginationArray(numPages, currentPage, 1),
    [numPages, currentPage]
  )

  return (
    <Container className={className}>
      {currentPage > 0 && (
        <ArrowLink to={pathBuilder(currentPage - 1)}>
          <img
            src="/images/pagination/ArrowLeft.svg"
            width="19"
            height="19"
            alt="previous"
          />
        </ArrowLink>
      )}

      {paginationArray.map((page, index) => (
        <React.Fragment key={index}>
          {index === paginationArray.length - 1 &&
            paginationArray.length > 1 &&
            paginationArray[paginationArray.length - 2] !== page - 1 && (
              <img src="/images/pagination/Dots.svg" alt="…" />
            )}

          {page === currentPage ? (
            <Current>
              {page + 1} {page === currentPage}
            </Current>
          ) : (
            <DigitLink to={pathBuilder(page)}>{page + 1}</DigitLink>
          )}

          {index === 0 &&
            paginationArray.length > 1 &&
            paginationArray[1] !== 1 && (
              <img
                src="/images/pagination/Dots.svg"
                width="13"
                height="4"
                alt="…"
              />
            )}
        </React.Fragment>
      ))}

      {currentPage < numPages - 1 && (
        <ArrowLink to={pathBuilder(currentPage + 1)}>
          <img
            src="/images/pagination/ArrowRight.svg"
            width="19"
            height="19"
            alt="next"
          />
        </ArrowLink>
      )}
    </Container>
  )
}

export default Pagination
