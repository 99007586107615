import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IMediaPostShort } from '../templates/MediaPage'
import { mediaPostPagePathBuilder } from '../templates/MediaPostPage.context'
import MediaContent from './MediaContent'

const LinkWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 12px;
  }
`

const Cover = styled.span`
  width: 100%;
  height: 100%;
  max-width: 146px;
  height: 85px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 243px;
    height: 141px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  flex: 1;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DateWrapper = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: ${({ theme }) => theme.colors.secondary};
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 14px;
    font-size: 16px;
    line-height: 19px;
  }
`

const Summary = styled.p`
  display: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

interface MediaCardProps {
  item: IMediaPostShort
}

const MediaCard: React.FC<MediaCardProps> = ({ item }) => {
  const date = useMemo(() => new Date(item.published_at), [item.published_at])
  const image = getImage(
    item.cover?.imageFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <LinkWrapper to={mediaPostPagePathBuilder(item.type, item.slug)}>
      {image && (
        <Cover>
          <GatsbyImage image={image} alt={item.cover?.name ?? ''} />
        </Cover>
      )}
      <Content>
        <Header>
          <MediaContent.Tag type={item.type} />
          <DateWrapper>{date.toLocaleDateString()}</DateWrapper>
        </Header>
        <Title>{item.title}</Title>
        <Summary>{item.description}</Summary>
      </Content>
    </LinkWrapper>
  )
}

export default MediaCard
