import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  padding: 0 10px 70px 10px;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 10px 0 10px;
  }
`

interface ChildProps {
  reducedAbout?: boolean
}

const Child = styled.div<ChildProps>`
  margin: 0 auto;
  max-width: 840px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'tabs'
    'about'
    'list';
  gap: 12px;

  // tabs
  & > *[class^='MediaTabs__List'] {
    margin-bottom: 10px;
    grid-area: tabs;
  }

  // about
  & > *[class^='MediaAbout__Container'] {
    grid-area: about;
  }

  // list
  & > *[class^='MediaList__Container'] {
    grid-area: list;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr minmax(171px, 171px);
    grid-template-areas:
      'tabs 1'
      'list about';
    row-gap: 20px;
    column-gap: 99px;

    // tabs
    & > *[class^='MediaTabs__List'] {
      margin-bottom: 0;
    }
  }

  ${({ reducedAbout }) =>
    reducedAbout &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-areas:
          'tabs'
          'list'
          'about';
      }
    `}
`

interface MediaContainerProps {
  reducedAbout?: boolean
  children: React.ReactNode
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  children,
  reducedAbout,
}) => (
  <Container>
    <Child reducedAbout={reducedAbout}>{children}</Child>
  </Container>
)

export default MediaContainer
